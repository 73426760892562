import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProductCard } from "./ProductCard";
import projImg1 from "../assets/img/trax run.webp";
import projImg2 from "../assets/img/circlapp.png";
import projImg3 from "../assets/img/salesforce_crm.png";
import projImg4 from "../assets/img/grooves.webp";
import projImg5 from "../assets/img/oneknoxclub.png";
import projImg6 from "../assets/img/ut_athletics.jpeg";
import colorSharp2 from "../assets/img/color-sharp2.png";

export const Products = () => {

  const products = [
    {
      title: "Trax Run Club",
      descriptions: [
        "IOS/Android",
        "October 2024 Release",
        "Social Running Platform",
        "BPM music based",
      ],
      imgUrl: projImg1,
    },
    {
      title: "Circl",
      descriptions: [
        "IOS/Android",
        "Community Engagement",
        "Event Management",
        "over 5,000 downloads"
      ],
      imgUrl: projImg2,
    },
 
    {
      title: "Grooves",
      descriptions: [
        "in Design & Development",
        "Golf course explorer",
        "Build-my-bag api",
      ],
      imgUrl: projImg4,
    },
    {
      title: "Business CRM",
      descriptions: [
        "Customer Relationship Management",
        "Sales Tracking Tools",
      ],
      imgUrl: projImg3,
    },
    {
      title: "One Knox",
      descriptions: [
        "in Design & Development",
        "Live sports feed",
        "Team updates & news",
      ],
      imgUrl: projImg5,
    },
    {
      title: "Tennessee Athletics",
      descriptions: [
        "Ultimate sports app",
        "Recruiting, scores, news",
        "Fan Engagement Tools",
      ],
      imgUrl: projImg6,
    },
  ];
  

  return (
    <section className="product" id="products">
      <Container>
        <Row>
          <Col size={12}>
            <div>
              <h2>Portfolio</h2>
              <p>At Godspeed, we develop powerful solutions tailored to meet the core needs of businesses. Whether you're looking for innovative CRM systems, tools to bring your startup ideas to life, or platforms that enhance customer engagement, our products are designed to drive success and growth.</p>
              {/* <Tab.Container id="products-tabs" defaultActiveKey="first">
                <Nav 
                  variant="pills" 
                  className="nav-pills mb-5 justify-content-center align-items-center product-tabs" 
                  id="pills-tab"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">Tab 1</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Tab 2</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Tab 3</Nav.Link>
                  </Nav.Item>
                </Nav> */}
                {/* <Tab.Content 
                  id="slideInUp" 
                  className="product-pane"
                > */}
                  {/* <Tab.Pane eventKey="first"> */}
                    <Row style={{padding: 30}}>
                      {
                        products.map((product, index) => {
                          return (
                            <ProductCard
                              key={index}
                              {...product}
                            />
                          )
                        })
                      }
                    </Row>
                  {/* </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p>Some content for Tab 2.</p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                  </Tab.Pane> */}
                {/* </Tab.Content>
              </Tab.Container> */}
            </div>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background Shape"/>
    </section>
  )
}
