

import {useState, useEffect} from "react";
import {Navbar, Container, Nav} from "react-bootstrap"


import logo from '../assets/img/godspeed_logo2.svg'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'


export const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, seScrolled] = useState(false);


    useEffect( () => {

        const onScroll = () => {
            if(window.scrollY > 50) {
                seScrolled(true);
            
            }
            else {
                seScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);
        
        return () => window.removeEventListener("scroll", onScroll);

    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }


    const handleConnectClick = () => {
      setActiveLink('connect');
      const contactElement = document.getElementById('connect');
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error("Contact element not found.");
      }
    };

    return ( <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="#home"><img src= {logo} alt = "Logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon"> </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className= {activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick ={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#services"  className= {activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick ={() => onUpdateActiveLink('services')}>Services</Nav.Link>
            <Nav.Link href="#products"  className= {activeLink === 'products' ? 'active navbar-link' : 'navbar-link'} onClick ={() => onUpdateActiveLink('products')}>Portfolio</Nav.Link>

          </Nav>
          <span className="navbar-text">
            {/* <div className="social-icon">
                <a href = "#"><img src={navIcon1} alt ="" /></a>
                <a href = "#"><img src={navIcon2} alt ="" /></a>
                <a href = "#"><img src={navIcon3} alt ="" /></a>
            </div> */}
            <button className= "vvd" onClick ={() => {handleConnectClick()}}><span>Let's Connect</span></button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )

}