import { Col } from "react-bootstrap";

export const ProductCard = ({ title, descriptions, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          {/* Map through the descriptions array to display each one */}
          {descriptions.map((desc, index) => (
            <span key={index} style={{ display: "block" }}>
              {desc}
            </span>
          ))}
        </div>
      </div>
    </Col>
  );
};
